<!--
 * @Author: Axs <i@axs.email>
 * @Date: 2021-11-05 17:43:44
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-11-05 17:49:12
 * @Description: 
 * @FilePath: /src/views/share/wechat.vue
-->
<template>
    <div class="page">

    </div>
</template>
<script>
    import { PAGE_ROOT } from 'page';
    export default {
        data() {
            return {

            }
        },
        created() {
            if (this.$store.getters.getExposeMethod.expandName == 'QiShiKa') {
                window.location.replace('https://lmtd.wutongjinyu.com/longmao/index.html');
            }
            else {
                this.$util.pageJump({
                    path: PAGE_ROOT,
                    config: {
                        jumpType: 1
                    }
                })
            }
        }
    }
</script>
